<template>
  <div>
    <el-date-picker
      :value-format="valueFormat"
      v-model="timeSection"
      :type="type"
      range-separator="-"
      :default-time="['00:00:00', '23:59:59']"
      @change="changeTime"
      :clearable="clearable"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      unlink-panels
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <i class="el-icon-date el-icon-date-custom"></i>
    <!-- <div v-if="showRange" class="selectRangeDate">
      <el-button @click="selectRangeDate(-1)" type="text">昨日</el-button>
      <el-button @click="selectRangeDate(30)" type="text">近30天</el-button>
      <el-button @click="selectRangeDate(90)" type="text">近90天</el-button>
      <el-button @click="selectRangeDate(180)" type="text">近180天</el-button>
    </div> -->
  </div>
</template>

<script>
import { get } from "lodash";
import { getZeroOrLastDateTime as getTime } from "@/common/js/utils";
export default {
  name: "a-time-picker",
  props: {
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
    type: {
      type: String,
      default: "datetimerange",
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true,
    },
    defalutDate: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showRange: {
      type: Boolean,
    },
  },
  watch: {
    valueFormat() {
      this.resetTime();
    },
    defalutDate: {
      handler(val) {
        if (val && val.length) {
          this.resetTime();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "昨日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近30天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近90天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近180天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeSection: [],
      timeArray: [getTime(this.valueFormat, 0), getTime(this.valueFormat, 0, false)], // 当天0点 - 23：59
      dayArray: [getTime(this.valueFormat, -1), getTime(this.valueFormat, 0)], // 昨天  - 今天
      monthArray: [getTime(this.valueFormat, -365), getTime(this.valueFormat, -30)], // 12月前 - 当月
      dayArray30: [getTime(this.valueFormat, -30), getTime(this.valueFormat, 0)], // 30天前  - 今天
      dayArray90: [getTime(this.valueFormat, -90), getTime(this.valueFormat, 0)], // 90天前  - 今天
      dayArray180: [getTime(this.valueFormat, -180), getTime(this.valueFormat, 0)], // 180天前  - 今天
    };
  },
  methods: {
    selectRangeDate(range) {
      if (range === -1) {
        this.timeSection = [
          this.dataTimeRest(-1) + " 00:00:00",
          this.dataTimeRest(-1) + " 23:59:59",
        ];
      } else {
        this.timeSection = this[`dayArray${range}`];
      }
      this.$emit("timeChange", this.timeSection, true);
    },
    formatValue(value) {
      return value < 10 ? `0${value}` : value;
    },
    dataTimeRest(num) {
      let yesTime = Date.now() + num * 24 * 60 * 60 * 1000;
      let yesDate = new Date(yesTime);
      let year = yesDate.getFullYear();
      let month = this.formatValue(yesDate.getMonth() + 1);
      let day = this.formatValue(yesDate.getDate());
      return year + "-" + month + "-" + day;
    },
    resetTime() {
      const { defalutDate, dataTimeRest, valueFormat } = this;
      let defDate = dataTimeRest(0);
      let timeSections = {
        "yyyy-MM-dd": this.dayArray,
        "yyyy-MM": this.monthArray,
      };
      console.log("defalutDate", defalutDate);
      if (defalutDate.length > 0) {
        // 如果有默认时间显示默认时间
        this.timeSection = [...defalutDate];
      } else {
        this.timeSection = get(timeSections, valueFormat) || [
          defDate + " 00:00:00",
          defDate + " 23:59:59",
        ];
      }
      this.$emit("timeChange", this.timeSection);
    },
    changeTime(val) {
      this.$emit("timeChange", val || ["", ""]);
    },
  },
  created() {
    // this.resetTime();
  },
  async mounted() {
    this.resetTime();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.selectRangeDate {
  padding: 0 10px;
  display: inline-block;
}
</style>
<style scoped>
.el-icon-date-custom{
  position: relative;
  left: -26px;
}

</style>
